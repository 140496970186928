/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getStyles, getImageUrl, getTrackerLink } from '../../../../helpers/getters';
import translate from '../../../../helpers/translations';

function AdvacedRow({
  item,
  site,
  market,
  trackerFormat,
  oneliner,
  tracker,
  number,
  affiliateName,
}) {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  const gotStyles = getStyles((style) => require('./' + style), ['adefault', site]);

  const trackerLink = `https://${affiliateName}${getTrackerLink(trackerFormat, item, tracker)}`;
  const name = item.bonus.operator_name || item.name;
  const { adefault: styles, [site]: siteStyles = {} } = gotStyles;

  const splitOneliner = item.one_liners[oneliner].one_liner.split('+');
  const couponCode = item.extra_fields?.coupon_code;
  const logoUrl =
    item.short_name !== 'betmgm_sports'
      ? item.standardised_logo_url
      : item.logo_url || item.standardised_logo_url;

  return (
    <li className={`${styles.item} ${siteStyles.item}`}>
      <div className={`${styles.topPart} ${siteStyles.topPart}`}>
        <div className={`${styles.logo} ${siteStyles.logo}`}>
          {logoUrl && (
            <a
              className={`${styles.image} ${siteStyles.image}`}
              target="_blank"
              href={trackerLink}
              rel="nofollow sponsored noreferrer noopener"
              data-rank={number}
            >
              <img
                className={`${styles.logo} ${siteStyles.logo}`}
                src={getImageUrl() + logoUrl}
                alt={name}
                data-rank={number}
              />
            </a>
          )}
          <div>
            <span>{name}</span>
            <div>
              <img
                alt="star"
                width={24}
                height={24}
                src={require('../../../../assets/images/star.png')}
              />
              <span className={`${styles.primaryRating} ${siteStyles.primaryRating}`}>
                {parseFloat(item.rating || 0)}
              </span>
              <span>/5</span>
            </div>
          </div>
        </div>
        <ul className={`${styles.sellingPoint} ${siteStyles.sellingPoint}`}>
          {item.selling_point_names.slice(0, 3).map((sellingPoint) => (
            <li>
              <img
                alt="check-circle"
                width={24}
                height={24}
                src={require('../../../../assets/images/check-circle.png')}
              />
              {sellingPoint}
            </li>
          ))}
        </ul>
      </div>
      {/* <div>payment methods go here</div> */}
      {couponCode && (
        <div className={`${styles.couponCode} ${siteStyles.couponCode}`}>
          {couponCode}
          <button type="button" onClick={() => !copySuccess && copyToClipBoard(couponCode)}>
            {copySuccess ? 'Copied' : 'Copy'}
            {!copySuccess && (
              <img
                alt="copy"
                width={16}
                height={16}
                src={require('../../../../assets/images/copy-two.png')}
              />
            )}
          </button>
        </div>
      )}
      <div className={`${styles.bottomPart} ${siteStyles.bottomPart}`}>
        <div>
          <p>{splitOneliner[0]}</p>
          <p>{splitOneliner[1]}</p>
        </div>
        <a
          className={`${styles.ctaButton} ${siteStyles.ctaButton}`}
          target="_blank"
          href={trackerLink}
          rel="nofollow sponsored noreferrer noopener"
        >
          {translate('Play', market)}

          <img
            alt="ctaButton-logo"
            width={24}
            height={24}
            src={require('../../../../assets/images/arrow-right.png')}
          />
        </a>
      </div>
    </li>
  );
}

AdvacedRow.propTypes = {
  item: PropTypes.shape({
    short_name: PropTypes.string,
    bonus: PropTypes.oneOfType([
      PropTypes.shape({
        operator_name: PropTypes.string,
      }),
      PropTypes.shape([]),
      PropTypes.any,
    ]),
    selling_point_names: PropTypes.shape([]),
    one_liners: PropTypes.shape({}),
    extra_fields: PropTypes.shape({
      terms_and_conditions: PropTypes.string,
      coupon_code: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    logo_url: PropTypes.string,
    standardised_logo_url: PropTypes.string,
    rating: PropTypes.string,
  }),
  affiliateName: PropTypes.string,
  number: PropTypes.number,
  site: PropTypes.string,
  market: PropTypes.string,
  trackerFormat: PropTypes.string,
  tracker: PropTypes.string,
  oneliner: PropTypes.string,
};

export default AdvacedRow;
