import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getStyles, getImageUrl, getTrackerLink, getOnelinerName } from '../../helpers/getters';
import translate from '../../helpers/translations';

const BonusTemplate = ({ widgetData, site, trackerFormat }) => {
  const { operator, tracker, affiliate_name } = widgetData;
  const gotStyles = getStyles((style) => require('./' + style), ['adefault', site]);
  const { adefault: styles, [site]: siteStyles = {} } = gotStyles;
  const trackerLink = `https://${affiliate_name}${getTrackerLink(
    trackerFormat,
    operator,
    tracker
  )}`;

  const onelinerName = getOnelinerName(widgetData);

  const market = operator?.market?.split('_')[0];

  const logoUrl =
    operator.short_name !== 'betmgm_sports'
      ? operator.standardised_logo_url
      : operator.logo_url || operator.standardised_logo_url;

  const oneliner = operator.one_liners[onelinerName]
    ? operator.one_liners[onelinerName]?.one_liner?.split('+') || []
    : operator.one_liners?.main?.one_liner?.split('+') || [];

  const containerChange = (elements) => {
    if (site === 'superdeporte') {
      return (
        <a
          className={`${styles.container} ${siteStyles.container}`}
          target="_blank"
          href={trackerLink}
          rel="nofollow sponsored noreferrer"
        >
          {elements}
        </a>
      );
    }
    return (
      <div
        className={`${styles.container} ${siteStyles.container}  ${
          siteStyles[`${operator.type}bg`]
        }`}
      >
        {elements}
      </div>
    );
  };
  const ref = useRef(null);
  const ref2 = useRef(null);
  useEffect(() => {
    if (ref.current && ref2.current) {
      window.parent.postMessage(
        {
          alturaIframe: ref.current.clientHeight + ref2.current.clientHeight,
          nombre: 'bonus-widget',
        },
        '*'
      );
    }
  }, []);

  return containerChange(
    <>
      <div ref={ref} className={`${styles.topPart} ${siteStyles.topPart}`}>
        {site === 'thesun' && (
          <img
            loading="lazy"
            className={`${styles.bgImage} ${siteStyles.bgImage}`}
            src={require(`../../assets/images/${operator.type}-${site}.png`)}
            alt="bg"
          />
        )}

        {site === 'talksport' && (
          <>
            <img
              loading="lazy"
              className={`${styles.bgImage} ${siteStyles.bgImage}`}
              src={require(`../../assets/images/talksport.png`)}
              alt="bg"
            />
            <img
              loading="lazy"
              className={`${styles.bgImageMob} ${siteStyles.bgImageMob}`}
              src={require(`../../assets/images/talksport-mob.png`)}
              alt="bg"
            />
          </>
        )}

        <a
          className={`${styles.image} ${siteStyles.image}`}
          target="_blank"
          href={trackerLink}
          rel="nofollow sponsored noreferrer"
        >
          <img
            className={`${styles.logo} ${siteStyles.logo}`}
            src={getImageUrl() + logoUrl}
            alt={operator.name}
          />
          {(!site || site === 'pr') && (
            <div className={styles.rating}>
              <span className={styles.ratingText}>{operator.name}</span>
              <div className={styles.ratingStars}>{parseFloat(operator.rating || 0)}/5</div>
            </div>
          )}
        </a>
        <a
          className={`${styles.info} ${siteStyles.info}`}
          target="_blank"
          href={trackerLink}
          rel="nofollow sponsored noreferrer"
        >
          {oneliner[0] && (
            <p className={`${styles.infoText} ${siteStyles.infoText}`}>{oneliner[0]}</p>
          )}
          {oneliner[1] && (
            <p className={`${styles.infoText} ${siteStyles.infoText}`}>+ {oneliner[1]}</p>
          )}
        </a>
      </div>
      <div ref={ref2} className={`${styles.cta} ${siteStyles.cta}`}>
        <a
          className={`${styles.ctaButton} ${siteStyles.ctaButton}`}
          target="_blank"
          href={trackerLink}
          rel="nofollow sponsored noreferrer"
        >
          {translate('Play', operator.market)}
          {site === 'superdeporte' && (
            <img
              alt="ctaButton-logo"
              width={27}
              height={27}
              src={require('../../assets/images/superdeporteCtaTwo.png')}
            />
          )}
        </a>
      </div>
      {operator.extra_fields?.terms_and_conditions && (
        <div
          className={`${styles.tandc} ${siteStyles.tandc} ${siteStyles[operator.type]} ${
            market === 'us' ? siteStyles.tandcUS : ''
          }`}
        >
          <p
            className={`${styles.tandcText} ${siteStyles.tandcText}`}
            dangerouslySetInnerHTML={{ __html: operator.extra_fields.terms_and_conditions }}
          />
        </div>
      )}
    </>
  );
};

BonusTemplate.propTypes = {
  widgetData: PropTypes.shape({
    tracker: PropTypes.string,
    operator: PropTypes.shape({
      extra_fields: PropTypes.shape({
        terms_and_conditions: PropTypes.string,
      }),
      one_liners: PropTypes.shape({
        main: PropTypes.shape({
          one_liner: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
      market: PropTypes.string,
      standardised_logo_url: PropTypes.string,
      rating: PropTypes.string,
    }),
  }),
  site: PropTypes.string,
  trackerFormat: PropTypes.string,
};

export default BonusTemplate;
