import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../helpers/getters';

const Tabs = ({ hasTabs, children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);
  const { adefault: styles } = getStyles((style) => require('./' + style));

  return hasTabs ? (
    <>
      <ol>
        {children.map(({ props: { label } }) => (
          <li
            key={label}
            className={`${styles.tab} ${activeTab === label && styles.active}`}
            role="tab"
            tabIndex={0}
            onClick={() => setActiveTab(label)}
            onKeyDown={() => setActiveTab(label)}
          >
            <h3>{label}</h3>
          </li>
        ))}
      </ol>
      {children.map(({ props: { label, ...rest } }) => {
        if (activeTab !== label) return null;
        return rest.children;
      })}
    </>
  ) : (
    children
  );
};

Tabs.propTypes = {
  hasTabs: PropTypes.bool,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        label: PropTypes.string,
        children: PropTypes.shape({}),
      }),
    })
  ),
};

export default Tabs;
