import { useEffect, useState } from 'react';
import { getOperator, getTracker } from '../helpers/getters';

export default (shortcode) => {
  const [widgetState, setWidgetState] = useState({
    isLoaded: false,
    isError: false,
    data: null,
    operator: null,
  });

  useEffect(() => {
    if (shortcode) {
      fetch(`${process.env.REACT_APP_API_URL}${shortcode}`)
        .then((res) => res.json())
        .then(
          (data) => {
            // eslint-disable-next-line
            console.info('Widget -> success -> data', data.success, data);
            if (!data.success) {
              setWidgetState({ isLoaded: false, isError: true });
            } else {
              setWidgetState({
                isLoaded: true,
                isError: false,
                data: Object.assign(data.result, {
                  operator: getOperator(data),
                  tracker: getTracker(data),
                }),
              });
            }
          },
          (err) => {
            // eslint-disable-next-line
            console.info('Widget -> error -> data', err.message);
            setWidgetState({ isLoaded: false, isError: true });
          }
        );
    }
  }, [shortcode]);

  return widgetState;
};
