import React from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../helpers/getters';
import Toplist from './toplist';
import Tabs from './tabs';

const ToplistTemplate = ({ widgetData, site, lang, trackerFormat }) => {
  const gotStyles = getStyles((style) => require('./' + style), ['adefault', site]);
  const { adefault: styles, [site]: siteStyles = {} } = gotStyles;

  return (
    <div className={`${styles.container} ${siteStyles.container}`}>
      <Tabs hasTabs={widgetData.items.length > 1}>
        {widgetData.items.map((item) => (
          <div key={item.label} label={item.label}>
            <Toplist
              toplist={item}
              site={site}
              lang={lang || widgetData.lang}
              showTitle={widgetData.items.length === 1}
              trackerFormat={trackerFormat}
              initItemsLoad={widgetData.number_of_items_initial_load}
              loadMoreItems={widgetData.number_of_items_load_more}
              affiliateName={widgetData.affiliate_name}
            />
          </div>
        ))}
      </Tabs>
    </div>
  );
};

ToplistTemplate.propTypes = {
  widgetData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    affiliate_name: PropTypes.string,
    lang: PropTypes.string,
    pretty_link: PropTypes.string,
    number_of_items_initial_load: PropTypes.number,
    number_of_items_load_more: PropTypes.number,
  }),
  site: PropTypes.string,
  lang: PropTypes.string,
  trackerFormat: PropTypes.string,
};

export default ToplistTemplate;
