const defaultTranslations = {
  br_pt: [{ Review: 'Recension', Play: 'Aposte já' }],
  se_sv: [{ Review: 'Recension', Play: 'Spela' }],
  pl_pl: [{ Review: 'Recenzja', Play: 'Zagraj' }],
  no_no: [{ Review: 'Les omtale', Play: 'Spill nå!' }],
  de_de: [{ Review: 'Bericht lesen', Play: 'Jetzt spielen!' }],
  za_en: [{ Review: 'Review', Play: 'Play' }],
  es_es: [{ Review: 'Review', Play: 'Visitar' }],
  gb_en: [{ Review: 'Review', Play: 'Claim Offer' }],
  co_es: [{ Review: 'Review', Play: '¡Juega Ya!' }],
  ar_es: [{ Review: 'Review', Play: '¡JUEGA YA!' }],
  default: [{ Review: 'Review', Play: 'Claim Offer' }],
};

export default (text, market, translations = defaultTranslations) =>
  translations[market] ? translations[market][0][text] : translations.default[0][text];
