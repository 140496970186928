import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../helpers/getters';
import BasicRow from '../rows/basic-row';
import AdvancedRow from '../rows/advanced-row';

const Toplist = ({
  toplist,
  site,
  lang,
  showTitle,
  trackerFormat,
  initItemsLoad = 0,
  loadMoreItems = 0,
  affiliateName,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { adefault: styles } = getStyles((style) => require('./' + style));

  const itemsPerPage = loadMoreItems !== 0 ? loadMoreItems : toplist.items.length;
  const indexOfLastItem =
    currentPage === 1
      ? initItemsLoad !== 0
        ? initItemsLoad
        : toplist.items.length
      : (currentPage - 1) * itemsPerPage + initItemsLoad;
  const currentItems = toplist.items.slice(0, indexOfLastItem);
  const last =
    initItemsLoad === 0 ? 1 : Math.ceil((toplist.items.length - initItemsLoad) / itemsPerPage + 1);
  const next = last === currentPage ? null : currentPage + 1;
  const ref = useRef(null);

  const Row = site === 'crickettimes' ? AdvancedRow : BasicRow;

  useEffect(() => {
    if (ref.current) {
      window.parent.postMessage(
        { alturaIframe: ref.current.clientHeight, nombre: 'toplist-widget' },
        '*'
      );
    }
  }, []);

  return (
    <div ref={ref}>
      {toplist.label && showTitle && React.createElement(toplist.tag ?? 'h2', {}, toplist.label)}
      {site === 'superdeporte' && (
        <div className={styles.description}>
          <p>Publicidad / 18+ / Juego Responsable</p>
          <p>
            Las promociones que ofrecen los casinos y casas de apuestas son solo para usuarios
            verificados y registrados al menos 30 días. Solo para mayores de 18 años. Términos y
            condiciones completas en la web oficial del operador
          </p>
        </div>
      )}
      <ul className={styles.list}>
        {currentItems.map((item, index) => (
          <Row
            key={item.id}
            number={index + 1}
            item={item}
            site={site}
            market={toplist.market}
            lang={lang}
            oneliner={toplist.oneliner}
            trackerFormat={trackerFormat}
            tracker={toplist.tracker}
            affiliateName={affiliateName}
          />
        ))}
      </ul>
      {next != null && (
        <button
          className={styles.loadMore}
          id={next}
          type="button"
          onClick={(e) => setCurrentPage(Number(e.target.id))}
        >
          Load More
        </button>
      )}
    </div>
  );
};

Toplist.propTypes = {
  toplist: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    market: PropTypes.string,
    lang: PropTypes.string,
    tracker: PropTypes.string,
    pretty_link: PropTypes.string,
    oneliner: PropTypes.string,
    tag: PropTypes.string,
    label: PropTypes.string,
  }),
  affiliateName: PropTypes.string,
  site: PropTypes.string,
  lang: PropTypes.string,
  showTitle: PropTypes.bool,
  trackerFormat: PropTypes.string,
  initItemsLoad: PropTypes.number,
  loadMoreItems: PropTypes.number,
};

export default Toplist;
