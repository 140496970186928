import siteNameAliases from '../constants/siteNameAliases';

export const getSiteNameAlias = (siteName) =>
  Object.keys(siteNameAliases).find(
    (aliasesSiteName) =>
      siteName === aliasesSiteName ||
      (siteName.includes(aliasesSiteName) && siteNameAliases[aliasesSiteName].includes(siteName))
  );

export const sanitizeSitename = (site, searchParam) => {
  const fullSite = site && site?.replace('.gigmedia.com', '');
  const siteName = fullSite ? getSiteNameAlias(fullSite) || fullSite : fullSite;

  return siteName || searchParam.site;
};

export const filterRibbons = (ribbons, result) => {
  let results;
  if (ribbons && result) {
    const resultIds = Object.keys(result);

    const filteredRibbons = ribbons?.filter((ribbonId) => resultIds?.includes(ribbonId.toString()));
    const filteredRibbonObjects = filteredRibbons.map((ribbonId) => result[ribbonId]);
    results = filteredRibbonObjects;
    return results;
  }
  return results;
};

export const getOnelinerName = (result) =>
  result?.items?.length > 0 && result.items[0].items.length > 0 ? result.items[0].oneliner : null;

export const getOperator = ({ result } = {}) =>
  result?.items?.length > 0 && result.items[0].items.length > 0 ? result.items[0].items[0] : null;

export const getTracker = ({ result } = {}) =>
  result?.items?.length > 0 ? result.items[0].tracker : null;

export const getImageUrl = () =>
  process.env.IMAGE_URL
    ? process.env.IMAGE_URL
    : 'https://cdn.toplistwidget.gigmedia.com/filters:format(webp)/fit-in/120x120/';

export const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    shortcode: searchParams.get('short_code'),
    site: searchParams.get('site'),
    lang: searchParams.get('lang'),
    trackerFormat: searchParams.get('pretty_link_format'),
  };
};

export const getTrackerLink = (format, operator, tracker = 'main') => {
  const defaultTracker = format || '[goto],short_name';
  const nonMainTracker = format || '[goto],short_name,[tracker]';

  const trackerArr = (
    tracker === 'main' ? defaultTracker : operator.links[tracker] ? nonMainTracker : defaultTracker
  )
    .replace('type,', operator?.type === 'casino' ? '' : 'type,')
    .split(',')
    .map((item) => {
      const customSection = item.match(/\[(.*)\]/);
      if (customSection) {
        const sectionName = customSection.pop();
        // return sectionName === 'tracker' ? tracker : sectionName;
        if (sectionName === 'tracker') {
          return tracker === 'main' ? null : tracker;
        }
        return sectionName;
      }

      if (item === 'country') {
        const markets = operator.market.split('_');
        if (markets.length === 2) {
          return markets[0];
        }
      }

      return operator[item];
    });

  return `/${trackerArr.filter((el) => el != null).join('/')}`;
};

export const getModule = (callback, fallback = null) => {
  try {
    return callback();
  } catch (err) {
    // eslint-disable-next-line
    console.info('Widget -> error -> module', err.message);
    return fallback;
  }
};

export const getStyles = (callback, styles = ['adefault']) =>
  styles.reduce((processed, style) => {
    if (!style) {
      return processed;
    }
    const siteNameAlias = getSiteNameAlias(style);
    return getModule(
      () => ({
        ...processed,
        [style]: callback(`${siteNameAlias || style}.module.scss`)?.default,
      }),
      processed
    );
  }, {});
