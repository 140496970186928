import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getStyles, getImageUrl, getTrackerLink } from '../../../helpers/getters';
import translate from '../../../helpers/translations';

const Advanced = ({
  widgetData: { operator, tracker, affiliate_name },
  site,
  trackerFormat,
  ribbons,
}) => {
  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };
  const gotStyles = getStyles((style) => require('./' + style), ['adefault', site]);

  const trackerLink = `https://${affiliate_name}${getTrackerLink(
    trackerFormat,
    operator,
    tracker
  )}`;
  const name = operator?.name;
  const { adefault: styles, [site]: siteStyles = {} } = gotStyles;
  const splitOneliner = operator.one_liners.main.one_liner.split('+');

  const couponCode = operator.extra_fields?.coupon_code;
  const logoUrl = operator.standardised_logo_url;

  return (
    <li className={`${styles.item} ${siteStyles.item}`}>
      <div className={`${styles.topPart} ${siteStyles.topPart}`}>
        <div className={`${styles.logo} ${siteStyles.logo}`}>
          {logoUrl && (
            <a
              className={`${styles.image} ${siteStyles.image}`}
              target="_blank"
              href={trackerLink}
              rel="nofollow sponsored noreferrer noopener"
            >
              <img
                className={`${styles.logo} ${siteStyles.logo}`}
                src={getImageUrl() + logoUrl}
                alt={name}
              />
            </a>
          )}
          <div>
            <span>{name}</span>
            <div>
              <img
                alt="star"
                width={24}
                height={24}
                src={require('../../../assets/images/star.png')}
              />
              <span className={`${styles.primaryRating} ${siteStyles.primaryRating}`}>
                {parseFloat(operator.rating || 0)}
              </span>
              <span>/5</span>
            </div>
          </div>
        </div>
        <div className={`${styles.oneLiner} ${siteStyles.oneLiner}`}>
          <p>{splitOneliner[0]}</p>
          <p>{splitOneliner[1]}</p>
        </div>
        {ribbons && ribbons.length > 0 && (
          <div className={`${styles.ribbon} ${siteStyles.ribbon}`}>{ribbons[0]?.label}</div>
        )}
        {couponCode && (
          <div className={`${styles.couponCode} ${siteStyles.couponCode}`}>
            {couponCode}
            <button type="button" onClick={() => !copySuccess && copyToClipBoard(couponCode)}>
              {copySuccess ? 'Copied' : 'Copy'}
              {!copySuccess && (
                <img
                  alt="copy"
                  width={16}
                  height={16}
                  src={require('../../../assets/images/copy-two.png')}
                />
              )}
            </button>
          </div>
        )}
      </div>

      <div className={`${styles.bottomPart} ${siteStyles.bottomPart}`}>
        <a
          className={`${styles.ctaButton} ${siteStyles.ctaButton}`}
          target="_blank"
          href={trackerLink}
          rel="nofollow sponsored noreferrer noopener"
        >
          {translate('Play', operator.market)}
          <img
            alt="ctaButton-logo"
            width={24}
            height={24}
            src={require('../../../assets/images/arrow-right.png')}
          />
        </a>
      </div>
    </li>
  );
};
export default Advanced;

Advanced.propTypes = {
  widgetData: PropTypes.shape({
    affiliate_name: PropTypes.string,
    tracker: PropTypes.string,
    operator: PropTypes.shape({
      extra_fields: PropTypes.shape({
        terms_and_conditions: PropTypes.string,
        coupon_code: PropTypes.string,
      }),
      selling_point_names: PropTypes.shape([]),
      one_liners: PropTypes.shape({
        main: PropTypes.shape({
          one_liner: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
      market: PropTypes.string,
      standardised_logo_url: PropTypes.string,
      rating: PropTypes.string,
    }),
  }),
  site: PropTypes.string,
  trackerFormat: PropTypes.string,
  ribbons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      language_id: PropTypes.number,
      site_id: PropTypes.number,
    })
  ),
};
