import React from 'react';
import PropTypes from 'prop-types';
import StarRating from 'react-star-ratings';
import { getStyles, getImageUrl, getTrackerLink } from '../../../../helpers/getters';
import translate from '../../../../helpers/translations';

const BasicRow = ({
  item,
  extraElement: EA,
  site,
  market,
  trackerFormat,
  oneliner,
  tracker,
  number,
  affiliateName,
}) => {
  const gotStyles = getStyles((style) => require('./' + style), ['adefault', site]);

  const trackerLink = `https://${affiliateName}${getTrackerLink(trackerFormat, item, tracker)}`;
  const name = item.bonus.operator_name || item.name;
  const { adefault: styles, [site]: siteStyles = {} } = gotStyles;
  const splitOneliner = item?.one_liners[oneliner]?.one_liner.split('+');
  const couponCode = item.extra_fields?.coupon_code;

  const logoUrl =
    item.short_name !== 'betmgm_sports'
      ? item.standardised_logo_url
      : item.logo_url || item.standardised_logo_url;

  return (
    <li className={`${styles.item} ${siteStyles.item}`} data-rank={number}>
      <div className={`${styles.topPart} ${siteStyles.topPart}`}>
        {logoUrl && (
          <a
            className={`${styles.image} ${siteStyles.image}`}
            target="_blank"
            href={trackerLink}
            rel="nofollow sponsored noreferrer noopener"
            data-rank={number}
          >
            <div
              className={`${styles.imageContainer} ${siteStyles.imageContainer}`}
              data-rank={number}
            >
              {(site === 'superdeporte' || site === 'crickettimes') && (
                <span className={`${styles.number} ${siteStyles.number}`}>{number}</span>
              )}
              <img
                className={`${styles.logo} ${siteStyles.logo}`}
                src={getImageUrl() + logoUrl}
                alt={name}
                data-rank={number}
              />
            </div>
            <div className={`${styles.rating} ${siteStyles.rating}`}>
              <span className={`${styles.ratingText} ${siteStyles.ratingText}`}>{name}</span>
              <div className={`${styles.ratingStars} ${siteStyles.ratingStars}`}>
                <StarRating
                  numberOfStars={site === 'crickettimes' || site === 'meutimao' ? 5 : 1}
                  rating={
                    site === 'crickettimes' || site === 'meutimao' ? parseFloat(item.rating) : 1
                  }
                  starRatedColor={site === 'pulzo' ? '#FFDE00' : '#f90'}
                  starEmptyColor="#BCBCBC"
                />
                {site !== 'crickettimes' && site !== 'meutimao' && (
                  <>
                    <span className={`${styles.primaryRating} ${siteStyles.primaryRating}`}>
                      {site === 'pulzo'
                        ? parseFloat(item.rating * 2 || 0)
                        : parseFloat(item.rating || 0)}
                    </span>
                    <span> {site === 'pulzo' ? '/10' : '/5'}</span>
                  </>
                )}
              </div>
            </div>
          </a>
        )}
        <a
          className={`${styles.info} ${siteStyles.info}`}
          target="_blank"
          href={trackerLink}
          rel="nofollow sponsored noreferrer noopener"
        >
          {site === 'talksport' && <div className={siteStyles.infoBG} />}
          {item.one_liners?.[oneliner]?.one_liner && (
            <>
              {site === 'superdeporte' && <p>• PROMOCIÓN •</p>}

              {site === 'crickettimes' ? (
                <>
                  <p>{splitOneliner[0]}</p>
                  <p>{splitOneliner[1]}</p>
                </>
              ) : (
                <p className={`${styles.infoText} ${siteStyles.infoText}`}>
                  {item.one_liners[oneliner].one_liner}
                </p>
              )}

              {/* Coupon code */}
              {site === 'pulzo' && couponCode && (
                <div className={siteStyles.couponContainer}>
                  <span>código bono:</span>
                  <span>
                    {couponCode}
                    <img
                      alt="ctaButton-logo"
                      width={16}
                      height={16}
                      src={require('../../../../assets/images/copy.png')}
                    />
                  </span>
                </div>
              )}
            </>
          )}
        </a>
        <div className={`${styles.cta} ${siteStyles.cta}`}>
          <a
            className={`${styles.ctaButton} ${siteStyles.ctaButton}`}
            target="_blank"
            href={trackerLink}
            rel="nofollow sponsored noreferrer noopener"
          >
            {translate('Play', market)}
            {site === 'superdeporte' && (
              <img
                alt="ctaButton-logo"
                width={22}
                height={22}
                src={require('../../../../assets/images/superdeporteCta.png')}
              />
            )}
            {site === 'pulzo' && (
              <img
                alt="ctaButton-logo"
                width={16}
                height={16}
                src={require('../../../../assets/images/external-link.png')}
              />
            )}
          </a>
          {site === 'talksport' && (
            <div className={siteStyles.ctaIcons}>
              {market?.split('_')[0] !== 'us' ? (
                <img
                  className={siteStyles.ctaIcon18}
                  alt="18+"
                  src={require('../../../../assets/images/18.png')}
                />
              ) : (
                <img
                  className={siteStyles.ctaIcon18}
                  alt="21+"
                  src={require('../../../../assets/images/21.png')}
                />
              )}
              <img
                className={siteStyles.ctaIconGA}
                alt="Gamble Aware"
                src={require('../../../../assets/images/begamble.png')}
              />
            </div>
          )}
        </div>
      </div>
      {item.extra_fields?.terms_and_conditions && (
        <div className={`${styles.tandc} ${siteStyles.tandc}`}>
          <p
            className={`${styles.tandcText} ${siteStyles.tandcText}`}
            dangerouslySetInnerHTML={{ __html: item.extra_fields.terms_and_conditions }}
          />
        </div>
      )}
      {EA && <EA data={item} />}
    </li>
  );
};

BasicRow.propTypes = {
  item: PropTypes.shape({
    short_name: PropTypes.string,
    bonus: PropTypes.oneOfType([
      PropTypes.shape({
        operator_name: PropTypes.string,
      }),
      PropTypes.shape([]),
      PropTypes.any,
    ]),
    one_liners: PropTypes.shape({}),
    extra_fields: PropTypes.shape({
      terms_and_conditions: PropTypes.string,
      coupon_code: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    logo_url: PropTypes.string,
    standardised_logo_url: PropTypes.string,
    rating: PropTypes.string,
  }),
  affiliateName: PropTypes.string,
  number: PropTypes.number,
  extraElement: PropTypes.elementType,
  site: PropTypes.string,
  market: PropTypes.string,
  trackerFormat: PropTypes.string,
  tracker: PropTypes.string,
  oneliner: PropTypes.string,
};

export default BasicRow;
