import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import fetchData from './api/data';
// import fetchRibbons from './api/ribbons';
import { getSearchParams, sanitizeSitename } from './helpers/getters';
import Error from './components/error';
import BonusTemplate from './components/bonus-template';
import ToplistTemplate from './components/toplist-template';
import Advanced from './components/bonus-template/advanced/advanced';
// import { filterRibbons } from './helpers/getters';

const App = () => {
  const searchParams = getSearchParams();

  const widgetState = fetchData(searchParams.shortcode);

  const siteName = sanitizeSitename(widgetState.data?.affiliate_name, searchParams);
  // const ribbons = siteName === 'crickettimes' ? fetchRibbons(searchParams.shortcode, widgetState.data?.affiliate_name) : null;
  // const filteredRibbons = siteName === 'crickettimes' ? filterRibbons(
  //   widgetState?.data?.operator.ribbons,
  //   ribbons?.ribbon?.result
  // ) : null;

  return (
    <React.StrictMode>
      {(!searchParams.shortcode && <Error error="Specify widget type and shortcode" />) ||
        (widgetState?.isError && <Error error="No data received or invalid shortcode" />) ||
        (widgetState?.isLoaded && (
          <Router>
            <div className="App">
              <Routes>
                <Route
                  path="/bonus"
                  element={
                    siteName === 'crickettimes' ? (
                      <Advanced
                        {...searchParams}
                        site={siteName}
                        widgetData={widgetState.data}
                        ribbons={null}
                      />
                    ) : (
                      <BonusTemplate
                        {...searchParams}
                        site={siteName}
                        widgetData={widgetState.data}
                      />
                    )
                  }
                />
                <Route
                  path="/toplist"
                  element={
                    <ToplistTemplate
                      {...searchParams}
                      site={siteName}
                      widgetData={widgetState.data}
                    />
                  }
                />
              </Routes>
            </div>
          </Router>
        ))}
    </React.StrictMode>
  );
};

export default App;
